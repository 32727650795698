body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(255, 217, 198, 255);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Navbar Starts */
.nav-bar{
  font-family: Nunito, sans-serif;
  padding: 1.5rem 15rem;
}
.navbar-brand{
  min-width: none;
  font-size: 2.5rem;
  font-weight: 1000;
  color: #ee7a00;
}

#responsive-navbar-nav{
  border: none;
}

.navbar-toggler {
  background-color: transparent; 
  border: none
}

.navbar-toggler :focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none;
  border-radius: none;
  border: none;
}

@media (max-width: 1201px) {
  .nav-bar{
    padding: 1rem;
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  .navbar-brand{
    
    font-size: 2rem;
  }

}

.mr-3{
  font-weight: 700;
  color: black;
}

.nav-bar .nav-link {
  transition: color 0.5s ease-in-out; /* Add transition property for smooth color change */
}

.nav-bar .nav-link:hover {
  color: #ee7a00; /* hover color */
}
/* Navbar Ends */

/* Footer Starts*/

.footer{
  font-family: Nunito, sans-serif;
  padding: 1.5rem 15rem!important;
  background-color: #ee7a00!important;
  color: azure;
  position: relative
}
@media (max-width: 780px) {
  .footer{
    padding: 3rem!important;
    text-align: center;
    
  }

}
.footer .mx-2{
  color: azure;
  font-size: 1.5rem;
}
.footer h3{
  font-size: 1.5rem;
  font-weight: 700;
}

.footer h5{
  margin-bottom: 2rem;
  font-weight: 700;
}

.footer .mx-2:hover{
  color: rgb(217, 198, 255);
}
/* Footer Ends*/

/* Hero Starts */
.hero-text{
  text-align: left;
  justify-content: center;
  align-items: center;
  /*background-image: url(./landingLogo.png);*/
  background-size: 45em;
  background-repeat: no-repeat;
  background-position: left;
  padding: 0 0 3rem 0;
}

.hero-text h1{
  font-family: Nunito, sans-serif;
  font-size: 4rem;
  font-weight: 900;
}

.hero-text h2{
  font-family: Nunito, sans-serif;
  font-size: 2rem;
  font-weight: 700;
}

.hero-text h4{
  font-family: Nunito, sans-serif;
  font-size: 1rem;
  font-weight: 700;
}

.hero-button{
  margin-top: 2em;
  border-color: transparent;
}

@media (max-width: 780px) {
  .hero-text{
    background-size: 18em;
    background-position: top;
    text-align: center;

  }

  .hero-text h2{
    margin: 2em;
  }
  
}

.hero-button {
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out; /* Add transition properties for smooth background color and text color change */
}

.hero-button:hover {
  background-color: #ee7a00!important; /* Change the background color to your desired hover color */
  color: whitesmoke !important; /* Change the text color to your desired hover color and add !important to override any other CSS styles */
  border-color: transparent !important; /* Optional: Set the border color to transparent to remove the border when hovering */
}
/* Hero Ends */

/* Wow Effects Start */
.wow.slideUp {
  animation-name: slideUp;
  animation-duration: 1s;
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
/* Wow Effects Ends*/

/* Contact starts*/

.hyperlink {
  color: #4a4a4a;
  text-decoration: none;
  transition: letter-spacing 0.3s ease-in-out;
}

.hyperlink:hover {
  letter-spacing: 2px;
}

/* Contact ends */
